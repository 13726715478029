@import "fonts.css";

.notion-page-offset {
	margin-top: 0;
}

.notion-title {
	margin-top: 0;
}

.codex-editor__redactor {
	padding-bottom: 80px !important;
}


@media (min-width: 651px) {
	.codex-editor--narrow .ce-toolbar__actions {
		left: -80px !important;
		border: 1px solid #e8e8eb;
		background-color: #fff;
		-webkit-box-shadow: 0 3px 15px -3px rgb(13 20 33 / 13%);
		box-shadow: 0 3px 15px -3px rgb(13 20 33 / 13%);
		border-radius: 6px;
		right: auto !important;
	}

	.codex-editor--narrow .codex-editor__redactor {
		margin-right: 0;
	}

	.ce-toolbar__settings-btn, .ce-toolbar__plus {
		width: 26px !important;
		border-radius: 5px;
	}

	.codex-editor--narrow .ce-toolbar__actions:last-child {
		padding-right: 0;
	}
}


.ce-inline-tool--link {
	display: none;
}
