@font-face {
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 300;
	src: url("../fonts/SourceSansPro/source-sans-pro-v18-cyrillic-300.woff2")
			format("woff2"),
		url("../fonts/SourceSansPro/source-sans-pro-v18-cyrillic-300.woff")
			format("woff");
}

@font-face {
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 400;
	src: url("../fonts/SourceSansPro/source-sans-pro-v18-cyrillic-regular.woff2")
			format("woff2"),
		url("../fonts/SourceSansPro/source-sans-pro-v18-cyrillic-regular.woff")
			format("woff");
}

@font-face {
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 600;
	src: url("../fonts/SourceSansPro/source-sans-pro-v18-cyrillic-600.woff2")
			format("woff2"),
		url("../fonts/SourceSansPro/source-sans-pro-v18-cyrillic-600.woff")
			format("woff");
}
